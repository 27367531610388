// @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
// @import "./hiq.scss";
// @import url("https://rsms.me/inter/inter.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: "Inter", sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
@import "./styles/base";
