@import "./variables";

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

.align-middle {
  vertical-align: middle !important;
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.border {
  border: 1px solid $divider;
  border-radius: $border-radius;
}

.clickable:hover {
  cursor: pointer;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.bg-primary {
  background-color: $vb-primary !important;
}

.bg-secondary {
  background-color: $vb-secondary !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-light-2 {
  background-color: $vb-light !important;
}

.bg-primary-light {
  background-color: $vb-primary-lightest !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.fill-primary {
  fill: $vb-primary;
}
.fill-secondary {
  fill: $vb-secondary;
}
.fill-success {
  fill: #28a745;
}
.fill-info {
  fill: #17a2b8;
}
.fill-warning {
  fill: #ffc107;
}
.fill-danger {
  fill: #dc3545;
}
.fill-light {
  fill: #f8f9fa;
}

.fill-muted {
  fill: $text-muted;
}

.fill-dark {
  fill: #282c3f;
}
.fill-white {
  fill: white;
}

.draggable:hover {
  cursor: grab;
}

.dragging:hover {
  cursor: grabbing;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float--clear {
  clear: both;
}

.d-flex {
  display: flex;
  &--x-centered {
    display: flex;
    justify-content: center;
  }
  &--y-centered {
    display: flex;
    align-items: center;
  }
  &--centered {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.flex-grow {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.d-grid {
  display: grid;
}

.d-grid.d-flow-col {
  grid-auto-flow: column;
}

.d-grid--two-col {
  display: grid;
  @media (min-width: $media-lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.d-grid.grid-gap-base,
.d-grid--two-col.grid-gap-base {
  gap: 1rem;
}

.d-grid.grid-gap-half,
.d-grid--two-col.grid-gap-half {
  gap: 0.5rem;
}

.d-grid.grid-gap-none,
.d-grid--two-col.grid-gap-none {
  gap: 0rem;
}

.d-grid.grid-gap-double,
.d-grid--two-col.grid-gap-double {
  gap: 2rem;
}

.buttons {
  display: grid;
  gap: 0.5rem;
  grid-auto-flow: column;
  @media (min-width: $media-lg) {
    gap: 0rem;
    display: flex;
    & > * + * {
      margin-left: 0.5rem;
    }
  }
}

.buttons.place-right {
  @media (min-width: $media-lg) {
    justify-content: flex-end;
  }
}

.buttons.place-center {
  @media (min-width: $media-lg) {
    justify-content: center;
  }
}

.mw-120 {
  max-width: 7.5rem;
}

.mw-240 {
  max-width: 15rem;
}

.mw-320 {
  max-width: 20rem;
}

.mw-480 {
  max-width: 30rem;
}

.mw-600 {
  max-width: 37.5rem;
}

.shadow-xs {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.shadow-inner {
  box-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
}
.shadow-outline {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
.shadow-none {
  box-shadow: none !important;
}

.border-none {
  border: none !important;
}

.divider {
  border-bottom: 1px solid $border;
  &--dotted {
    border-bottom: 1px dotted $border;
  }
  &--dashed {
    border-bottom: 1px dashed $border;
  }
}

.text-align {
  &--left {
    text-align: left !important;
  }
  &--center {
    text-align: center !important;
  }
}

.nav-link {
  color: $vb-primary;
  transition: all 0.25s ease-out;
  &--with-underlined {
    border-bottom: 1px dashed $vb-primary;
    &:hover {
      border-bottom: 1px solid $vb-primary;
    }
  }

  &:hover {
    border-bottom: 1px dashed $vb-primary;
  }
}

.no-space-wrap {
  white-space: nowrap;
}

.print-hidden {
  @media print {
    display: none;
  }
}

.print-only {
  display: none;
  @media print {
    display: initial;
  }
}

// .rekram {
//   @page {
//     size: A4;
//     margin: 1rem;

//     @bottom-right {
//       content: "Generated using SwiftCV";
//       font-size: 16px;
//       color: #333;
//     }
//   }
// }

.no-page-break {
  &--before {
    page-break-before: avoid;
    break-before: avoid-page;
  }
  &--after {
    page-break-after: avoid;
    break-after: avoid-page;
  }
  &--within {
    page-break-inside: avoid;
    break-inside: avoid-page;
  }
}

.always-page-break {
  &--before {
    page-break-before: always;
    break-before: always;
  }
  &--after {
    page-break-after: always;
    break-after: always;
  }
  &--within {
    page-break-inside: always;
  }
}

.pro-badge {
  background-color: $vb-secondary;
  padding: 0.125rem 0.25rem;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  letter-spacing: 0.035rem;
  vertical-align: middle;
  border-radius: 3px;
  display: inline-block;
}

.retain-content-spacing {
  white-space: pre-wrap;
}

.border {
  &--default {
    border: 1px solid $border;
    border-radius: $border-radius;
  }
  &--primary {
    border-radius: $border-radius;
    border: 1px solid $vb-primary;
  }
}

.text-gradient {
  background: -webkit-linear-gradient(180deg, #ff3f6c 1.78%, #7527ff 98.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient {
  background: -webkit-linear-gradient(180deg, #ff3f6c 1.78%, #7527ff 98.62%);
  color: white;
}

.integration-clr {
  &--blue {
    background-color: #1a73e8;
  }
  &--pink {
    background-color: #ff3f6c;
  }
  &--black {
    background-color: #282c3f;
  }
  &--yellow {
    background-color: #ffdd00;
  }
  &--green {
    background-color: #27ae60;
  }
  &--maroon {
    background-color: #d33752;
  }
  &--purple {
    background-color: #bd5fff;
  }
  &--gradient {
    background: -webkit-linear-gradient(180deg, #ff3f6c 1.78%, #7527ff 98.62%);
  }
}
