@import "./variables";

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.25rem;
  font-weight: 400;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}

h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}
@media print {
  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem !important;
  }
}

@media print {
  h4,
  .h4 {
    font-size: 1.25rem !important;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
  @media print {
    font-size: 1.125rem !important;
  }
}

h6,
.h6 {
  font-size: 1rem;
  @media print {
    font-size: 0.875rem;
  }
}

.heading {
  font-size: 1.125rem !important;
  @media print {
    font-size: 1rem !important;
  }
}

small,
.small {
  font-size: 0.875rem !important;
  @media print {
    font-size: 0.75rem !important;
  }
}

.smaller {
  font-size: 0.75rem !important;
  letter-spacing: 0.0125rem;
  @media print {
    font-size: 0.625rem !important;
  }
}

.smallest {
  font-size: 0.625rem !important;
  letter-spacing: 0.01rem;
  @media print {
    font-size: 0.5rem !important;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #024dbc;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

caption,
.caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
  font-size: 0.875rem;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-medium {
  font-weight: 500 !important;
}

.font-weight-semibold {
  font-weight: 600 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-primary {
  color: $vb-primary !important;
}

.text-secondary {
  color: $vb-secondary !important;
}

.text-success {
  color: #28a745 !important;
}

.text-info {
  color: #17a2b8 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark,
.text-body {
  color: $vb-dark !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-super-muted {
  color: $text-super-muted !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
