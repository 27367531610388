@import "./variables";
@import "./buttons";
@import "./components";
@import "./misc";
@import "./responsive";
@import "./spacers";
@import "./typography";
@import "./forms";
@import "./loader";
@import "./shimmer";

html {
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $vb-font-family;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: $vb-dark;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

code {
  font-family: $vb-font-code;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

a {
  text-decoration: none !important;
  &:hover {
    cursor: pointer;
  }
}

@media print {
  button,
  input,
  textarea,
  select {
    display: none;
  }
  @page {
    size: A4; /* DIN A4 standard, Europe */
    margin: 8mm 8mm 8mm 8mm !important;
    orphans: 4;
    widows: 4;
  }
  html,
  body {
    // width: 210mm;
    // height: 282mm;
    font-size: 14px !important;
    background: #fff;
    overflow: visible;
  }
  div[data-section-heading] {
    break-after: avoid-page;
  }
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: inline-block;
}
