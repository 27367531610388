@import "styles/variables";

$loader-color: white;

.loader {
  font-size: 0.875rem;
  text-indent: -9999em;
  width: 0.875rem;
  display: inline-block;
  height: 0.875rem;
  border-radius: 50%;
  background: $loader-color;
  background: -moz-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: -webkit-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: -o-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: -ms-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: linear-gradient(to right, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  position: relative;
  -webkit-animation: loader-anim 0.5s infinite linear;
  animation: loader-anim 0.5s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  vertical-align: text-top;
  &:before {
    width: 50%;
    height: 50%;
    background: $loader-color;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
  }
  &:after {
    background: $vb-primary;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
}

.loader--is-primary {
  @extend .loader;
  $loader-color: $vb-primary;
  background: $loader-color;
  background: -moz-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: -webkit-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: -o-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: -ms-linear-gradient(left, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  background: linear-gradient(to right, $loader-color 10%, rgba(255, 0, 0, 0) 42%);
  &:after {
    background: #fff;
  }
}

@-webkit-keyframes loader-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes loader-anim {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
