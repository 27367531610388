@import "./variables";

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: $media-sm) {
  .container,
  .container-sm {
    max-width: 600px;
  }
}

@media (min-width: $media-md) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: $media-lg) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 990px;
  }
}

@media (min-width: $media-xl) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    // max-width: 1216px;
    max-width: 1024px;
  }
}

@media (min-width: $media-xxl) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    // max-width: 1320px;
    max-width: 1200px;
  }
}

.col-single {
  max-width: $cols-single;
}

@media print {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
}
