@import "./variables";

$white: #fff;

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.4;
  color: $vb-dark;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  letter-spacing: 0.01rem;
  border: 1px solid $border;
  padding: 0.655rem 1.5rem;
  font-size: 0.875rem;
  border-radius: $border-radius;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  letter-spacing: 0.015rem;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: $vb-dark;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
  &:hover {
    cursor: not-allowed !important;
  }
}

.btn-icon {
  svg {
    width: 1.125rem;
    height: 1.125rem;
    vertical-align: middle !important;
    margin-right: 0.25rem;
  }
}

.btn-sm.btn-icon {
  svg {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: -1px;
    margin-right: 0.15rem;
  }
}

.btn-primary {
  color: $white;
  background-color: $vb-primary;
  border-color: $vb-primary;
}
.btn-primary:hover {
  color: $white;
  background-color: #025ce2;
  border-color: #0257d5;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: $white;
  background-color: #025ce2;
  border-color: #0257d5;
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: $white;
  background-color: #0257d5;
  border-color: #0252c9;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled,
.btn-primary.disabled {
  color: $white;
  background-color: $vb-primary;
  border-color: $vb-primary;
  &:hover {
    cursor: not-allowed !important;
  }
}

.btn-secondary {
  color: $white;
  background-color: $vb-secondary;
  border-color: $vb-secondary;
}
.btn-secondary:hover {
  color: $white;
  background-color: darken($vb-secondary, 10);
  border-color: darken($vb-secondary, 10);
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
  color: $white;
  background-color: darken($vb-secondary, 10);
  border-color: darken($vb-secondary, 10);
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: $white;
  background-color: darken($vb-secondary, 10);
  border-color: darken($vb-secondary, 10);
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled,
.btn-secondary.disabled {
  color: $white;
  background-color: lighten($vb-secondary, 20);
  border-color: lighten($vb-secondary, 20);
}

.btn-success {
  color: $white;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: $white;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
  color: $white;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: $white;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
  color: $white;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-info {
  color: $white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: $white;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
  color: $white;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: $white;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
  color: $white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-danger {
  color: $white;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: $white;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
  color: $white;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: $white;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
  color: $white;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #212529;
  background-color: #f7f8ff;
  background-color: white;
  // border-color: transparent;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-primary-light {
  color: $vb-primary;
  background-color: $vb-primary-lightest;
  border-color: transparent;
  &:hover {
    color: $vb-primary;
    background-color: darken($vb-primary-lightest, 3%);
    border-color: transparent;
  }
  &:focus,
  &:active,
  &:checked {
    color: $vb-primary;
    background-color: $vb-primary-lightest;
    border-color: darken($vb-primary-lightest, 3%);
  }
  &:disabled {
    color: $text-muted;
    background-color: $vb-primary-lightest;
    border-color: transparent;
    &:hover {
      cursor: not-allowed;
    }
  }
}

.btn-check:focus + .btn-light,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  &:hover {
    cursor: not-allowed;
  }
}

.btn-dark {
  color: $white;
  background-color: $vb-dark;
  border-color: $vb-dark;
}
.btn-dark:hover {
  color: $white;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
  color: $white;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  color: $white;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
  color: $white;
  background-color: $vb-dark;
  border-color: $vb-dark;
}

.btn-outline-primary {
  color: $vb-primary;
  border-color: $vb-primary;
}
.btn-outline-primary:hover {
  color: $white;
  background-color: $vb-primary;
  border-color: $vb-primary;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  color: $white;
  background-color: $vb-primary;
  border-color: $vb-primary;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: $vb-primary;
  background-color: transparent;
}

.btn-outline-secondary {
  color: $vb-secondary;
  border-color: $vb-secondary;
}
.btn-outline-secondary:hover {
  color: $white;
  background-color: $vb-secondary;
  border-color: $vb-secondary;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
  color: $white;
  background-color: $vb-secondary;
  border-color: $vb-secondary;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: $vb-secondary;
  background-color: transparent;
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: $white;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
  color: $white;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: $white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
  color: $white;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: $white;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
  color: $white;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: $vb-dark;
  border-color: $vb-dark;
}
.btn-outline-dark:hover {
  color: $white;
  background-color: $vb-dark;
  border-color: $vb-dark;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
  color: $white;
  background-color: $vb-dark;
  border-color: $vb-dark;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: $vb-dark;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: $vb-primary;
  text-decoration: underline;
}
.btn-link:hover {
  color: #024dbc;
}
.btn-link:disabled,
.btn-link.disabled {
  color: $vb-secondary;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  border-radius: $border-radius;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: $border-radius;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.link-primary {
  color: $vb-primary;
}
.link-primary:hover,
.link-primary:focus {
  color: darken($vb-primary, 10);
}

.link-secondary {
  color: $vb-secondary;
}
.link-secondary:hover,
.link-secondary:focus {
  color: darken($vb-secondary, 10);
}

.link-success {
  color: #28a745;
}
.link-success:hover,
.link-success:focus {
  color: #19692c;
}

.link-info {
  color: #17a2b8;
}
.link-info:hover,
.link-info:focus {
  color: #0f6674;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover,
.link-danger:focus {
  color: #a71d2a;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover,
.link-light:focus {
  color: #cbd3da;
}

.link-dark {
  color: $vb-dark;
}
.link-dark:hover,
.link-dark:focus {
  color: #121416;
}
