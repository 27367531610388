@import "styles/variables";

@keyframes pulsate {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }

  50% {
    opacity: 1;
    transform: translateY(-4px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.logo-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  &__content {
    top: 40%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__logo {
    animation: pulsate 2s ease-out infinite;
    width: 5rem;
    height: 5rem;
    display: block;
  }
  progress {
    margin-top: 2rem;
    max-width: 7.5rem;
    height: 4px;
    color: $vb-primary;
    transition: width 150ms ease-in;
  }
}
