// $vb-primary: #0d6efd;
$vb-primary: #1a73e8;
$vb-primary-dark: #185abc;
$vb-primary-light: #4285f4;
$vb-primary-lighter: #a8caf6;
$vb-primary-lightest: #f5f9ff;
$vb-secondary: #ff3f6c;
$vb-secondary-dark: #cc3256;
$vb-secondary-light: #ff6589;
$vb-secondary-lighter: #ff8ca7;
$vb-secondary-lightest: #fff2f5;
$vb-success: #28a745;
$vb-info: #17a2b8;
$vb-warning: #ffc107;
$vb-danger: #dc3545;
$vb-light: #f9fafb;
$vb-dark: #282c3f;
$vb-dark-muted: #515774;

$vb-font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$vb-font-code: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$text-color: #282c3f;
$text-muted: #716f6f;
$text-super-muted-color: #a9abb3;
$text-super-muted: #a9abb3;
$divider: #eaeaec;
$border: #dfe1e5;
$accent-color: #282c3f;

$media-sm: 40rem;
$media-md: 48rem;
$media-lg: 64rem;
$media-xl: 80rem;
$media-xxl: 87.5rem;
$cols-single: 45rem;

$border-radius: 1.5rem;

$zindex-dropdown: 1000 !default;
$zindex-month-picker-dialog: 9;
$zindex-floater-nav: 99;
$zindex-modal: 999;

$box-shadow: 0 0.5rem 1rem rgba(#000, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba(#000, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba(#000, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba(#000, 0.075) !default;
