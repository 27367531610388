.shimmer {
  width: 100%;
}
.shimmer .shimmer-logo {
  height: 65px;
  width: 65px;
  border-radius: 50%;
  background-color: #eaeaea;
}
.shimmer .shimmer-comment {
  height: 0.5rem;
  background: #777;
  margin-top: 0.25rem;
  margin-bottom: 1.125rem;
  border-radius: 8px;
  background-color: #eaeaea;
}

.shimmer .w80 {
  width: 80%;
}
.shimmer .w25 {
  width: 25%;
}

.shimmer .w50 {
  width: 50%;
}

.shimmer > .w100 {
  width: 100%;
}

.shimmer .shimmer-animate {
  animation: shimmer-animation 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer-animation {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
