@import "./variables";

.form-label {
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  color: $text-muted;
  margin-bottom: 0.1rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 1rem;
  color: $text-muted;
}

.form-control {
  display: block;
  width: 100%;
  max-width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3;
  color: $vb-dark;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid $border;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.25s ease-out;

  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  outline: 0;
  box-shadow: inset 0 0 0 2px $vb-primary;
}
.form-control::placeholder {
  color: $text-muted;
  opacity: 1;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #fafafa;
  opacity: 1;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem;
}

.form-control-color::-moz-color-swatch {
  border-radius: 0.25rem;
}

.form-control-color::-webkit-color-swatch {
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
.form-select:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px $vb-primary;
}
.form-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.form-select[multiple],
.form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: $text-muted;
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem;
  &:hover {
    cursor: pointer;
  }
}
.form-check .form-check-input {
  margin-left: -1.75em;
  margin-right: 0.35em;
  vertical-align: middle;
}

.form-check-input {
  width: 1em;
  height: 1em;

  font-size: 0.875rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:hover {
    cursor: pointer;
  }
}
@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none;
  }
}
.form-check-input[type="checkbox"] {
  border-radius: $border-radius;
}
.form-check-input[type="radio"] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  outline: 0;
  box-shadow: inset 0 0 0 2px $vb-primary;
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type="radio"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type="checkbox"]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-check-label {
  font-size: 0.875rem;
  display: inline;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
  }
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238bbafe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.form-file {
  position: relative;
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 0;
  opacity: 0;
  padding: 0.5rem 0.75rem;
  &:hover {
    cursor: pointer;
  }
}
.form-file-input:focus-within ~ .form-file-label {
  border-color: #8bbafe;
  box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.form-file-input[disabled] ~ .form-file-label .form-file-text,
.form-file-input:disabled ~ .form-file-label .form-file-text {
  background-color: #e9ecef;
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  border-color: $border;
  border-radius: $border-radius;
}

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #495057;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  font-size: 0.875rem;
  color: #495057;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.form-file-sm {
  --bs-form-file-height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem;
}
.form-file-sm .form-file-text,
.form-file-sm .form-file-button {
  padding: 0.25rem 0.5rem;
}

.form-file-lg {
  --bs-form-file-height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem;
}
.form-file-lg .form-file-text,
.form-file-lg .form-file-button {
  padding: 0.5rem 1rem;
}

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-range:focus {
  outline: none;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: $border-radius;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bed8fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: $border-radius;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: $border-radius;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bed8fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: $border-radius;
}
.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: $border-radius;
  -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    -ms-transition: none;
    transition: none;
  }
}
.form-range::-ms-thumb:active {
  background-color: #bed8fe;
}
.form-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.form-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: $border-radius;
}
.form-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: $border-radius;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-file .form-file-input:focus ~ .form-file-label {
  z-index: 3;
}
.input-group > .form-file > .form-file-input:focus {
  z-index: 4;
}
.input-group > .form-file:not(:last-child) > .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .form-file:not(:first-child) > .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: $border-radius;
}

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: $border-radius;
}

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: $border-radius;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem;
}

.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group > .dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.form-group {
  border: 1px solid $border;
  border-radius: $border-radius;
  padding: 0.5rem 0.75rem;
  width: fit-content;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}
