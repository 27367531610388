@import "./variables";

@mixin media-sm {
  @media (min-width: $media-sm) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: $media-md) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: $media-lg) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: $media-xl) {
    @content;
  }
}

@mixin media-xxl {
  @media (min-width: $media-xxl) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
