@import "styles/variables";
@import "styles/animations";
@import "styles/responsive";
@import "styles/misc";
@import "styles/mixins";

.modal {
  position: relative;
  z-index: $zindex-modal;

  &__bg {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: center;

    &--defaultBg {
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  &__window {
    background-color: white;

    align-self: center;
    &--mobileFullScreen {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }

    @include media-md {
      border-radius: $border-radius;
    }

    @include media-lg {
      position: fixed;
      margin: 0;
      position: relative;
      max-height: calc(100vh - 60px);
      overflow-y: auto;
    }
  }
}

.modal__bg--defaultBg > .modal__window {
  @extend .shadow;
}
