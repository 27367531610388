/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: scale-up-center 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}

@-webkit-keyframes scale-up-center-sm {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center-sm {
  0% {
    -webkit-transform: scale(0.99);
    transform: scale(0.99);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.scale-up-center-sm {
  -webkit-animation: scale-up-center-sm 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: scale-up-center-sm 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}

/*--------------------------------*/

@-webkit-keyframes slide-from-top {
  0% {
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-from-top {
  0% {
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.slide-from-top {
  -webkit-animation: slide-from-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-from-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*--------------------------------*/

@-webkit-keyframes slide-from-bottom {
  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-from-bottom {
  0% {
    -webkit-transform: translateY(1rem);
    transform: translateY(1rem);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.slide-from-bottom {
  -webkit-animation: slide-from-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-from-bottom 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*--------------------------------*/

@keyframes fade-in {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-in 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/*--------------------------------*/

@-webkit-keyframes slide-from-bottom-sm {
  0% {
    -webkit-transform: translateY(0.25rem);
    transform: translateY(0.25rem);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes slide-from-bottom {
  0% {
    -webkit-transform: translateY(0.25rem);
    transform: translateY(0.25rem);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.slide-from-bottom-sm {
  -webkit-animation: slide-from-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-from-bottom 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0.85);
    transform: scaleY(0.85);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0.85);
    transform: scaleY(0.85);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}

.scale-in-ver-top {
  -webkit-animation: scale-in-ver-top 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-top 0.15s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/**
 * ----------------------------------------
 * animation scale-in-ver-top
 * ----------------------------------------
 */

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
